.Intro-Full {
    position: relative;
    min-height: 700px;
    background-image: url('../assets/images/selfee2.jpg');
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.Intro-Full::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(10, 0, 0, 0.1); /* Adjust the 0.5 value to change the opacity of the mask */
}


.Intro {
    display: flex;
    align-items: center;
    width: 80vw;
    margin: 50px auto 50px auto;    
    flex-direction: row;
}

.self-pic {
    position: relative;
    margin:  50px;
    width: 20vw;
    height: 20vw;
    border: 3px solid black;
    border-radius: 2px;
}

.small-container {
    padding: 30px;
    border-radius: 20px;
    background-color: white;
    color: black;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    margin:  50px;
    width: 80%;
    height: 80%;
    font-family: 'mont', sans-serif; 
}


.intro-title {
    font-weight: bold;
}


  
.download-button:hover {
    background-color: gray;
}

.download-button {
    border: 2px solid black;
    padding: 5px 15px 5px 15px;
    border-radius: 4px;
    color: black;
    font-size: 0.9em;
    font-weight: bold;
    transition: background-color 0.3s ease;
}


@media (max-width: 1250px) and (min-width: 1111px) {
    .self-pic {
        position: relative;
        margin:  50px;
        width: 30vw;
        height: 30vw;
        border: 3px solid black;
        border-radius: 2px;
    }
    
}



@media (max-width: 1111px) {

    .Intro {
        display: flex;
        align-items: center;
        width: 70vw;
        margin: 50px auto 50px auto;    
        flex-direction: column;
    }

    .self-pic {
        position: relative;
        margin:  50px;
        width: 60vw;
        height: 60vw;
        border: 3px solid black;
    }

    .small-container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        margin:  20px;
        width: 100%;
        height: 100%;
        font-family: 'mont', sans-serif;
    }
}