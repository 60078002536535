.skillset-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    min-height: 80vh;
    background-image: url('../assets/images/black-bg-skills.jpg');
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.skillset-left {
    flex: 65%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.skillset-right {
    flex: 35%;
    color: aliceblue;
    font-size: 50px;
    font-weight: bold;
    font-family: 'mont', sans-serif;
}


.skills-boxes, .skills-boxes-2 {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 10px 0px 10px 0px; 
}


.skill-box {
    justify-content: center;
    align-items: center;
    color: white;
    padding: 20px;
    box-sizing: border-box;

    width: 450px;
    height: 200px; 
    margin: 10px;

    background-color: rgba(0,0,0, 0.6);
    box-sizing: border-box;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}


.skill-title {
    font-weight: bold;
}



@media (min-width: 830px) and (max-width: 1230px) {

    .skillset-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    
        min-height: 100vh;
        background-image: url('../assets/images/black-bg-skills.jpg');
        /* Create the parallax scrolling effect */
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: rgba(0, 0, 0, 0.5); 
    }
    .skills-boxes, .skills-boxes-2 {
        position: relative;
        display: flex;
        flex-direction: row;
        margin: 10px 0px 10px 0px; 
    }

    .skillset-right {
        color: aliceblue;
        font-size: 30px;
        font-weight: bold;
        font-family: 'mont', sans-serif;
        justify-content: center;
        align-items: center;
        margin: 50px;
    }

}



@media (max-width: 1010px) {
    .skillset-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    
        min-height: 100vh;
        background-image: url('../assets/images/black-bg-skills.jpg');
        /* Create the parallax scrolling effect */
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: rgba(0, 0, 0, 0.5); 
    }

    .skills-boxes, .skills-boxes-2 {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 10px 0px 10px 0px; 
    }
    

    .skill-box {
        justify-content: center;
        align-items: center;
        color: white;
        padding: 20px;
        box-sizing: border-box;
    
        width: 80vw;
        height: auto; 
        margin: 10px;
    
        background-color: rgba(0, 0, 0);
        box-sizing: border-box;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        border: 2px solid aliceblue;
    }

    
    .skillset-left {
        flex: 65%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }


    .skillset-right {
        color: aliceblue;
        font-size: 30px;
        font-weight: bold;
        font-family: 'mont', sans-serif;
        justify-content: center;
        align-items: center;
        margin: 50px 50px 80px 50px;
    }
    
}
