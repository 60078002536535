

.footer-container {
    align-items: center;
    width: 80vw;
    margin: 50px auto 10px auto;  
}


.form-container {
    margin: 10px 10px 10px auto; 
    align-items: center;  
}

.send-container {
    flex: 30%;
}

.more-container {
    margin: 0px 0px 0px 100px; 
    flex: 20%;
    display: flex;
    flex-direction: column;
}


.contact-form, .send-title{
    font-weight: bold;
}


.send-intro {
    position: relative; 
}


.about-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
}


.about-container-1, .about-container-2 {
    margin: 10px;
}

.about-container-1 {
    flex: 60%;
}
.about-container-2 {
    flex: 40%;
}

.button-style {
    margin: 10px 10px 10px 0;
    align-items: center;
    padding: 5px;
    width:60px;
    height:40px; 
    background-color:black;
    border: 5px solid black; 

}

.button-style:active {
    border: 10px solid black !important; 
}



@media (max-width: 768px) {
    .more-container {
        margin: 30px;
        display: flex;
        flex-direction: column;
    }

    .send-container {
        flex: 30%;
        margin: 30px;
    }
    
}