.blog-button {
    background-color: white;
    color: black;
    border: 3px solid black;
    border-radius: 20px;
    font-weight: bold;
    padding: 8px 16px;
    font-size: 16px;
    font-family: 'Mont', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.blog-button:hover {
    background-color: black;
    color: white;
    border-color: aliceblue;
}