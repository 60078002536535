.download-button:hover {
    background-color: gray;
}

.download-button {
    border: 2px solid black;
    padding: 5px 10px 5px 10px;
    border-radius: 4px;
    color: black;
    font-size: 0.9em;
    font-weight: bold;
    transition: background-color 0.3s ease;
}