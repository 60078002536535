


.hobbies-container {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: auto;
    box-sizing: border-box;
    overflow: hidden;
    border-top: 1px solid gray;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
}


.carousel-container {
    flex: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../assets/images/bg_hobbies.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
}

.center-setting {
    display: flex;
    justify-content: center;
    align-items: center;
}


.image-setting {
    width: auto;
    height: 60vmin;
}

.div-test {
    display: flex;
    justify-content: center;
    align-items: center;
}


.caption-setting {
    color: black ;
}


.chinese-font {
    font-family: "SimSun", sans-serif;
    font-weight: bold;
}


.eng-font {
    font-family: 'mont', sans-serif; 
    font-weight: bold;
}

.eng-font-content {
    font-family: 'mont', sans-serif; 
}

@keyframes slideIn {
0% {
    opacity: 0;
    transform: translateX(100%);
}
100% {
    opacity: 1;
    transform: translateX(0);
}
}

.description-container p {
    animation: slideIn 0.5s ease;
    animation-fill-mode: forwards;
    opacity: 0;
}


.description-container {
    flex: 35%;
    overflow: hidden;
    margin: 50px 50px 20px 50px;
    font-size: 1.35em;
    font-family: 'SimSun', sans-serif;
}


.scrollable-content {
    overflow: auto;
    max-height: 40vh; /* Adjust the height as per your requirement */
}


.scrollable-content::-webkit-scrollbar {
    width: 0.3em; /* Adjust the width as per your preference */
  }
  
  .scrollable-content::-webkit-scrollbar-track {
    background: transparent; /* Set the track color to transparent */
  }
  
  .scrollable-content::-webkit-scrollbar-thumb {
    background-color: rgb(0,0,0,0.5) ; /* Set the thumb color to transparent */
  }


@media (max-width: 830px) {
    .hobbies-container {
        justify-content: center;
        align-items: center;
        flex-direction: column;

        overflow: hidden;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
    }
    
    
    .carousel-container {
        margin: 50px 0px 20px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
    }


    .image-setting {
        margin: 10px;
        width: auto; 
        height: auto;

        align-items: center;
        justify-content: center;
    }

    .description-container {
        margin: 50px 30px 50px 30px;
        font-size: 1.2em;
    }
}