
.chinese-font {
    font-family: "SimSun", sans-serif;
}




.content-font {
    font-size: 1.5em !important;
    /* font-weight: bold; */
    padding: 0.5em 0em 0.5em 1em !important;
}

.content-font-other {
    font-size: 1.2em !important;
    /* font-weight: bold; */
    padding: 0.5em 0em 0.5em 1em !important;
    font-family: 'mont';
    color: black !important;
}

.body-container {
    width: 65% !important;
}

.sidebar-setting {
    margin-right: 120px;
    font-weight: bold;
}


@media (max-width: 768px) {
    .content-font {
        font-size: 1em !important;
        /* font-weight: bold; */
        padding: 0.5em 0em 0.5em 1em !important;
    }
}