.Content {
  text-align: center;
}

.Content-header {
  /* ...existing styles... */
  opacity: 0; /* Set initial opacity to 0 */
}

.content-description,
.content-button, 
.content-dipper {
  opacity: 0; /* Set initial opacity to 0 */
  transition: opacity 2s ease-in-out; /* Add transition effect */
}


.tooltipmsg {
  position: relative;
  bottom: 5px;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltipmsg .tooltiptext {
  visibility: hidden;
  width: 350px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 20px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltipmsg:hover .tooltiptext {
  visibility: visible;
}



@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate {
  opacity: 1;
  transition: opacity 2s ease-in;
}

.animate-rotate {
  animation: rotateCircle 10s ease-in-out;
}

@keyframes rotateCircle {
  from {
    transform: translate(-50%, -28%) rotate(0);
  }
  to {
    transform: translate(-50%, -28%) rotate(-360deg);
  }
}

.container {
  display: flex;
  flex-direction: column;
}

.content-description {
  font-family: "SimSun", sans-serif;
  color: white;
  position: relative;
  font-size: 2em;
  margin: 100px 100px 0px 100px; 
  text-align: left;
}

.content-dipper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -28%);
  width: 500px;
  height: 500px;
  /* border: 3px solid #73AD21; */
}

.big-dipper {
  width: 70vmin; /* Adjust the width to fit your image */
}


.content-button {
  font-family: "SimSun", sans-serif;
  position: relative;
  text-align: left;
}

.aboutme-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.aboutme-button:hover {
  background-color: #2980b9;
}

.aboutme-button {
  margin: 0px 0px 0px 100px;
  padding: 5px;
  border: 3px solid white;
  border-radius: 4px;
  background-color: transparent;
  color: #fff;
  font-size: 1.5em;
  transition: background-color 0.3s ease;
}

.content-date {
  font-size: 0.7em;
}



.jup-star {
  position: absolute;
  width: 300px;
  height: auto;
}



@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .content-description {
    font-family: "SimSun", sans-serif;
    color: white;
    position: relative;
    font-size: 1.2em;
    margin: 50px 50px 10px 50px; /* Adjust the padding value to your preference */
    text-align: left;
  }

  .content-dipper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -18%);

    /* border: 3px solid #73AD21; */
  }

  .aboutme-button {
    margin: 0px 0px 60px 50px;
    padding: 10px;
    border: 3px solid white;
    border-radius: 4px;
    background-color: transparent;
    color: #fff;
    font-size: 1em;
    transition: background-color 0.3s ease;
  }

  @keyframes rotateCircle {
    from {
      transform: translate(-50%, -18%) rotate(0);
    }
    to {
      transform: translate(-50%, -18%) rotate(-360deg);
    }
  }


  .content-date {
    font-size: 0.9em;
  }


  .tooltipmsg .tooltiptext {
    visibility: hidden;
    width: 220px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 20px;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }


  .chinese-date {
    font-size: 0.9em;
  }
  
}