body {
  margin: 0;
  font-family: "SimSun", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "SimSun", sans-serif;
}

.full-container {
  display: flex;
  flex-direction: column;
}

.whole-container {
  width: 100vw;
  height: 100vh;
  position: relative;
  flex-direction: column;
  overflow: hidden;
}

.whole-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./assets/images/starSky.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  animation: backgroundScale 30s linear infinite;
  z-index: -1;
}

.whole-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 0;
}

@keyframes backgroundScale {
  0% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}



.header-container {
  height: 0%;
}

.content-container {
  flex-grow: 1;
}

.middle-container, .foot-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  font-family: 'mont', sans-serif;
} 

@font-face {
  font-family: 'felixti';
  src: url('./fonts/FELIXTI.TTF') format('truetype');
}

@font-face {
  font-family: 'mont';
  src: url('./fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
}